import { render, staticRenderFns } from "./badd.vue?vue&type=template&id=4237eb26&scoped=true&"
import script from "./badd.vue?vue&type=script&lang=js&"
export * from "./badd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4237eb26",
  null
  
)

export default component.exports