<template>
    <div class="post" v-if="ready" ref="container" 
        @touchstart="swipeStart($event)" @touchend="swipeEnd($event)">

        <div class="head">
            <div class="back" @click="back()">
                <back :size="'20px'"/>
            </div>
            <div class="dp">
                <img :src="post.user.dp"/>
            </div>
            <div class="mid" @click="goto_profile()">
                <div class="flex_name">
                    <div class="name">{{post.user.firstname}} {{post.user.lastname}}</div>
                    <verified :size="'18px'" :style="{'height': '18px'}" v-if="post.user.verified"/>
                </div>
                <div class="info">@{{post.user.username}} · {{date}}</div>
            </div>
            <div class="opt" @click="togglepop('show_opt')">
                <optionicon :size="'15px'" :style="{'height': '15px'}"/>
            </div>
        </div>

        <loader class="l" v-if="post_loading"/>

        <div v-else>
            <post_content :post="post" :hidden="hidden" :blocked="blocked"
                @unhide="hidden = false" @unblock="blocked = false"/>

            <div class="stat">
                <div>
                    <b>{{post.stat.reactions}}</b> <span class="g"> Reactions </span> · 
                    <b>{{post.stat.comments}}</b> <span class="g">Comments</span> 
                </div>
            </div>
            
            <post_react :post="post" @write_comment="write_comment()"/>


            <div class="box" @click="write_comment()">
                <div class="my_dp">
                    <img :src="$root.$data.dp"/>
                </div>
                <div>
                    <div class="text">Write a comment...</div>
                    <div class="flex">
                        <imageicon :size="'15px'"/>
                        <videoicon :size="'15px'"/>
                        <mention :size="'15px'"/>
                    </div>
                </div>
            </div>

            <div class="scroll">
                <div class="menu">
                    <div class="item" @click="fetch_comment()"
                        :class="[view == 'comments' ? 'a' : '']">
                        {{post.stat.comments}} Comments
                    </div>

                    <div class="item" @click="fetch_reactions('likes')" 
                        :class="[view == 'likes' ? 'a' : '']">
                        <liked :size="'20px'"/>
                        {{post.stat.like}}
                    </div>
                    <div class="item" @click="fetch_reactions('loves')"  
                        :class="[view == 'loves' ? 'a' : '']">
                        <loved :size="'20px'"/>
                        {{post.stat.love}}
                    </div>
                    <div class="item" @click="fetch_reactions('goods')"
                        :class="[view == 'goods' ? 'a' : '']">
                        <goodd :size="'20px'"/>
                        {{post.stat.good}}
                    </div>
                    <div class="item" @click="fetch_reactions('bads')"
                        :class="[view == 'bads' ? 'a' : '']">
                        <badd :size="'20px'"/>
                        {{post.stat.bad}}
                    </div>
                    <div class="item" @click="fetch_reactions('copys')"
                        :class="[view == 'copys' ? 'a' : '']">
                        <copy :size="'20px'"/>
                        {{post.stat.copy}}
                    </div>
                </div>
            </div>
            
            <loader class="l" v-if="loading"/>

            <div v-else>
                <div v-if="view == 'comments'">

                    <div class="empty_comment" v-if="!loading && comments.length == 0">
                        <commenticon :size="'30px'"/>
                        <div class="empty_text">This post has no comment yet</div>
                    </div>

                    <div class="comments" v-if="comments.length > 0">
                        <comment v-for="comment in comments" :key="comment.id" :comment="comment"/>
                    </div>

                </div>
                <div v-if="view == 'likes'" class="reactions">
                    <react_users v-for="user in likes" :key="user.username" :user="user"/>
                    
                </div>
                <div v-if="view == 'loves'" class="reactions">
                    <react_users v-for="user in loves" :key="user.username" :user="user"/>
                </div>
                <div v-if="view == 'goods'" class="reactions">
                    <react_users v-for="user in goods" :key="user.username" :user="user"/>
                </div>
                <div v-if="view == 'goods'" class="reactions">
                    <react_users v-for="user in goods" :key="user.username" :user="user"/>
                </div>
                <div v-if="view == 'copys'" class="reactions">
                    <react_users v-for="user in copys" :key="user.username" :user="user"/>
                </div>
            </div>
        </div>
        
        
        <div class="popup" v-if="pop">
            <div class="dark" @click="closepop()"></div>

            <transition name="slide">
                <write_comment @new_comment="new_comment($event)" v-if="show_write"/>
            </transition>

            <transition name="slide">
                <post_options @close="closepop()" @new_follow="new_follow($event)" 
                    @hide_post="hide_post()" @block_user="block_user()"
                    v-if="show_opt" :post="post" :settings="settings"/>
            </transition>
        </div>
    </div>
</template>

<script>

import pull_to_refresh from '../../mixins/pull_to_refresh.js'



import Localbase from 'localbase'
let db = new Localbase('ikotun')

import back from '../../icons/back.vue';
import verified from '../../icons/verified.vue';
import optionicon from '../../icons/option.vue';

import post_content from '../../components/post/content.vue';
import post_react from '../../components/post/post_react.vue';

import imageicon from '../../icons/image.vue'
import videoicon from '../../icons/video.vue';
import mention from '../../icons/mention.vue';
import commenticon from '../../icons/comment.vue'

import liked from '../../icons/liked.vue';
import loved from '../../icons/loved.vue';
import goodd from '../../icons/goodd.vue';
import badd from '../../icons/badd.vue'
import copy from '../../icons/copy.vue'

import loader from '../../components/loader.vue'

import comment from '../../components/post/comment.vue'
import react_users from '../../components/post/react_users.vue';

import write_comment from '../../components/post/write_comment.vue';
import post_options from '../../components/post/options.vue';

import moment from 'moment'
export default {
    components: {
        back, verified, optionicon,
        post_content, post_react,
        imageicon, videoicon, mention, commenticon,
        liked, loved, goodd, badd, copy,
        loader,
        comment, react_users,
        write_comment, post_options
    },
    mixins: [pull_to_refresh],
    data() {
        return {
            post_loading: false,
            post: {},
            date: '',
            ready: false,
            
            settings: {
                copy: true,
                comment: true,
                reaction: true
            },
            hidden: false,
            blocked: false,

            pop: false,
            show_write: false,
            show_opt: false,

            view: 'comments',

            loading: false,

            comments: [],
            likes: [],
            loves: [],
            goods: [],
            bads: [],
            copys: []
        }
    },
    created() {
        const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {
            if (this.pop) {
                this.closepop()
                next(false)
            } else {
                next()
            }
        })

        this.$once('hook:destroyed', () => {
            unregisterRouterGuard()
        })
    },
    activated() {

        let id = this.$route.params.id
        
        let post = JSON.parse(localStorage.getItem('post'))

        if (post.id == id) {
            
            this.post = post
            this.date = moment(this.post.date).fromNow()
            this.ready = true
            
            if (this.post.media.length > 5) {
                this.media = JSON.parse(this.post.media)
            }

            if (this.post.settings) {
                for( let key in this.post.settings) {
                    this.settings[key] = this.post.settings[key]
                }
            }
        }

    },
    mounted() {
        let id = this.$route.params.id
        
        let post = JSON.parse(localStorage.getItem('post'))

        if (post.id == id) {
            this.init_post(post)
        }

        
        db.collection('blocked_users').doc({ username: this.post.user.username }).get()
        .then(doc => {
            if (doc) {
                this.blocked = true
            }
        })

        db.collection('hidden_post').doc({ post_id: this.post.id }).get()
        .then(doc => {
            if (doc) {
                this.hidden = true
            }
        })
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        goto_profile() {
            localStorage.setItem('profile', JSON.stringify(this.post.user))

            setTimeout(() => {
                this.$router.push('/profile/' + this.post.user.username)
            }, 250);
        },
        refresh() {
            this.post_loading = true

            this.likes = []
            this.loves = []
            this.goods = []
            this.bads = []
            this.copys = []

            let form = new FormData()
            form.append('post_id', this.$route.params.id)

            this.$http.post('/post/one', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    let post = r.post[0]
                    this.init_post(post)

                    this.post_loading = false
                }
            })
        },
        init_post(post) {
            this.post = post
            this.date = moment(this.post.date).fromNow()
            this.ready = true
            
            if (this.post.media.length > 5) {
                this.media = JSON.parse(this.post.media)
            }

            if (this.post.settings) {
                for( let key in this.post.settings) {
                    this.settings[key] = this.post.settings[key]
                }
            }
            this.fetch_comment()
        },
        fetch_comment() {

            this.view = 'comments'

            this.loading = true

            let form = new FormData()
            form.append('post_id', this.post.id)

            this.$http.post('/post/comments', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.comments = r.comments

                    this.loading = false
                }
            })
        },
        fetch_reactions(how) {
            this.view = how

            if (this[how].length != 0) { return }

            this.loading = true

            let form = new FormData()
            form.append('reaction', how.slice(0, how.length - 1))
            form.append('post_id', this.post.id)

            this.$http.post('/post/reactions', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this[how] = r.reactions
                    this.loading = false
                }
            })
        },
        react(how) {

            if (!this.settings.reaction) {
                this.reacting_off(); return
            }

            let form = new FormData()
            form.append('reaction', how)
            form.append('post_id', this.post.id)

            this.$http.post('/post/react', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    let new_react = {
                        'id': this.post.id
                    }

                    if (this.post.reaction == how) {
                        r.stat[how]--
                        r.stat['reactions']--

                        this.post.reaction = ''
                        new_react['reaction'] = ''
                    } else {
                        r.stat[how]++
                        r.stat['reactions']++

                        if (this.post.reaction) {
                            r.stat[this.post.reaction]--
                            r.stat['reactions']--
                        }
                        this.post.reaction = how
                        new_react['reaction'] = how
                    }

                    new_react['stat'] = r.stat
                    this.$root.$data.new_react = new_react

                    this.post.stat = r.stat
                }
            })
        },
        new_comment(text) {
            let form = new FormData()
            form.append('text', text)
            form.append('post_id', this.post.id)

            this.$http.post('/post/comment', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.comments.unshift({
                        'id': r.id,
                        'post_id': this.post.id,
                        'date': r.date,
                        'text': text,
                        'reaction': null,
                        'username': this.$root.$data.username,
                        'stat': {
                            'like': 0,
                            'dislike': 0,
                            'replys': 0
                        },
                        'user': {
                            'username': this.$root.$data.username,
                            'firstname': this.$root.$data.firstname,
                            'lastname': this.$root.$data.lastname,
                            'dp': this.$root.$data.dp
                        }
                    })
                    r.stat.comments++
                    this.post.stat = r.stat
                    this.closepop()
                    this.commented()
                }
            })
        },
        new_follow(e) {
            this.post.user.following = e
            this.closepop()
        },
        hide_post() {
            this.hidden = true
            this.closepop()
        },
        block_user() {
            this.blocked = true
            this.closepop()
        },
        togglepop(what) {
            this.pop = true
            setTimeout(() => {
                this[what] = true
            }, 100);
        },
        closepop() {
            this.show_write = false
            this.show_opt = false
            setTimeout(() => {
                this.pop = false
            }, 200);
        },
        write_comment() {
            if (!this.settings.comment) {
                this.comment_off(); return 
            }

            this.togglepop('show_write')
        },
    },
    notifications: {
        commented: {
            type: 'success',
            title: 'Success',
            message: 'Your comment has been added'
        },

        reacting_off: {
            type: 'info',
            title: 'Cannot React',
            message: 'The author has turned off reacting'
        },
        comment_off: {
            type: 'info',
            title: 'Cannot Comment',
            message: 'The author has turned off commenting'
        }
    }
}
</script>

<style scoped>

    .post {
        position: relative;
        height: 100vh;
        overflow: auto;
        background-color: white;
    }

    .head {
        display: grid;
        grid-template-columns: max-content  max-content auto max-content;
        align-items: center;
        gap: 15px;
        padding: 10px 15px;
        border-bottom: 1px solid #ddd;
        width: 100vw;
        box-sizing: border-box;
        background-color: white;

        position: sticky;
        top: 0px;
        left: 0px;
        z-index: 3;
    }
    .back {
        fill: var(--main);
    }
    
    .dp {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .dp img {
        width: 40px;
    }
    .mid {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }

    .flex_name {
        display: flex;
        align-items: center;
        gap: 10px;
        fill: var(--main);
    }
    .name {
        font-weight: 700;
        display:inline;
        transition: .3s;
    }
    .name:active {
        background-color: var(--trans);
        color: var(--main);
    }
    .info {
        font-size: 12px;
        color: gray;
        margin-top: 3px;
    }
    .opt {
        margin-left: auto;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--trans);
        fill: var(--main);
        transition: .3s;
    }
    .opt:active {
        background-color: var(--main);
        fill: white;
    }
    
    .stat {
        border-top: 1px solid #ddd;
        padding: 15px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .g {
        color: grey;
    }

    
    .reaction {
        border-top: 1px solid #ddd;
        display: grid;
        grid-template-columns: 20% 20% 20% 20% 20%;
        align-items: center;
    }
    .action {
        text-align: center;
        padding: 15px 0px;
    }
    .action img {
        width: 25px;
        filter: grayscale(100%);
    }
    .a img {
        filter: none;
    }

    .box {
        background-color: #edeef0;
        margin: 15px;
        margin-top: 0px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        gap: 15px;
        padding: 15px;
    }
    
    .my_dp {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .my_dp img {
        width: 40px;
    }
    
    .text {
        color: grey;
        font-size: 14px;
    }
    .flex {
        margin-top: 5px;
        display: flex;
        gap: 10px;
        fill: var(--main);
    }


    .scroll {
        background-color: white;
        position: sticky;   
        top: 61px;
        left: 0px;
        z-index: 3;

    }
    .menu {
        display: flex;
        /* grid-template-columns: auto auto auto auto auto auto; */
        overflow: auto;
        
        width: 100%;
        border: 1px solid #ddd;
        border-left: none;
        border-right: none;

    }
    .item {
        padding: 12px 15px;
        display: flex;
        gap: 5px;
        font-size: 14px;
        align-items: center;
        word-break: keep-all;
        white-space: nowrap;
        border-bottom: 2px solid transparent;
        fill: var(--main);
    }
    .item img {
        width: 20px;
    }
    .a {
        color: var(--main);
        font-weight: 500;
        border-bottom-color: var(--main);
    }

    .l {
        padding: 70px 0px;
    }


    .comments {
        padding-top: 10px;
        background-color: #edeef0;
    }
    .empty_comment {
        padding: 70px 0px;
        text-align: center;
        fill: #555;
    }
    .empty_text {
        margin-top: 10px;
        color: gray;
        font-size: 14px;
        font-weight: 500;
    }
    .reactions {
        background-color: #edeef0;
        padding: 15px;
        padding-bottom: 1px;
    }






    .popup {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        z-index: 3;
    }
    .dark {
        position: fixed;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .slide-enter-active {
        animation: move .3s;
    }
    .slide-leave-active {
        animation: .2s move reverse;
    }
    @keyframes move {
        from {
            margin-bottom: -50vh;
        }
        to {
            margin-bottom: 0px;
        }
    }
</style>