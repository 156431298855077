<template>
    <div>

        <svg xmlns="http://www.w3.org/2000/svg" :width="size" :height="size" viewBox="0 -960 960 960">
            <path d="M480-417q-54.91 0-103.211 24.011Q328.489-368.978 299-323q-8 13-2.353 25.5T315-285q7.619 
            0 13.81-4 6.19-4 10.19-10 24-35 61.778-52t80-17Q523-368 560.5-350q37.5 18 61.5 52 5 6 10.5 
            9.5T646-285q13 0 19.5-12t-1.5-23q-29-47-78.747-72Q535.507-417 480-417Zm146-116q22.5 0 
            38.25-15.75T680-587q0-22.5-15.75-38.25T626-641q-22.5 0-38.25 15.75T572-587q0 22.5 15.75 
            38.25T626-533Zm-292 0q22.5 0 38.25-15.75T388-587q0-22.5-15.75-38.25T334-641q-22.5 0-38.25 
            15.75T280-587q0 22.5 15.75 38.25T334-533ZM480-80q-83 
            0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 
            156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Z"/>
        </svg>


    </div>
</template>

<script>
export default {
    props: ['color', 'size']
}
</script>

<style scoped>

</style>