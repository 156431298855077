<template>
    <div class="write">
        <div class="title">
            Write Comment
        </div>
        <div class="main">
            <textarea placeholder="Write a comment..." ref="textarea" v-model="text"></textarea>

            <loading v-if="loading" :height="'41px'"/>
            <div class="btn" v-else @click="comment()">Comment</div>
        </div>
    </div>
</template>

<script>
import loading from '../loading.vue';

export default {
    components: {
        loading
    },
    mounted() {
        this.$refs.textarea.focus()
    },
    data() {
        return {
            text: '',
            loading: false
        }
    },
    methods: {
        comment() {
            let text = this.text.replace(/(\r\n|\r|\n){2,}/g, '$1\n');
            let trim_text = text.trim()

            if (trim_text == '') {
                this.empty(); return
            }

            this.loading = true

            this.$emit('new_comment', trim_text)
        }
    },
    notifications: {
        empty: {
            'type': 'warn',
            'title': 'Empty',
            'message': 'Your comment cannot be empty'
        }
    }
}
</script>

<style scoped>
    
    .write {
        background-color: white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        width: 100%;
        position: relative;
        z-index: 2;
        transition: .2s;
    }
    .title {
        padding: 20px;
        border-bottom: 1px solid #ddd;
        font-weight: 500;
    }
    .main {
        /* height: 50vh; */
        padding: 15px;
        grid-gap: 15px;
        overflow: auto;
    }

    textarea {
        height: 40vh;
        width: 100%;
        border: none;
        outline: none;
        margin-bottom: 15px;
    }

    .btn {
        padding: 10px;
        text-align: center;
        border-radius: 5px;
        background-color: var(--main);
        color: white;
        font-weight: 500;
    }
</style>